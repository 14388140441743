import axios from '@axios'
import useJwt from '@/auth/jwt/useJwt'

export default {
  namespaced: true,
  state: {
    isBusyLoading: true,
    profileData: null,
  },
  getters: {},
  mutations: {
    UPDATE_BUSY_LOADING_STATE(state, val) {
      state.isBusyLoading = val
    },
    UPDATE_PROFILE_DATA_STATE(state, val) {
      state.profileData = val
    },
  },
  actions: {
    // (查詢)使用者資訊
    getProfileInfoData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/user/info', { headers: { 'Access-Token': useJwt.getToken() }, params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (更新)使用者資訊
    setProfileInfoUpdate(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/user/info', {
          method: 'PATCH',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (上傳)使用者大頭貼
    setUserImageUpload(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/user/avatar', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: resolveData.data,
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (刪除)使用者大頭貼
    setUserImageDelete() {
      return new Promise((resolve, reject) => {
        axios('/user/avatar', {
          method: 'DELETE',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // (變更)使用者密碼
    setUserPasswordChange(ctx, resolveData) {
      return new Promise((resolve, reject) => {
        axios('/user/password', {
          method: 'POST',
          headers: {
            'Access-Token': useJwt.getToken(),
          },
          data: {
            ...resolveData,
          },
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
