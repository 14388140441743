<template>
  <div class="avatar-preview">
    <b-skeleton-wrapper :loading="userProfileState.isBusyLoading">
      <template #loading>
        <div class="d-flex justify-content-center flex-column align-items-center w-100">
          <div class="profile-image mb-2">
            <b-avatar
              size="14rem"
              rounded
              variant="light-primary"
            />
          </div>

          <div class="d-flex flex-column ml-1">
            <div class="m-50">
              <h2 class="font-weight-bolder text-center">
                Name
              </h2>
              <span class="show-text">example@email.com</span>
            </div>
          </div>
        </div>
      </template>
    </b-skeleton-wrapper>

    <div v-if="!userProfileState.isBusyLoading">
      <div class="d-flex justify-content-center flex-column align-items-center w-100 mb-2">
        <div class="profile-image mb-2">
          <b-avatar
            ref="previewEl"
            size="14rem"
            rounded
            :src="userProfileState.profileData.image"
            variant="light-primary"
          />

          <div class="profile-image-mask animate__animated animate__fadeIn">
            <div
              class="profile-icon profile-image-edit"
              @click="$refs.refInputEl.$el.click()"
            >
              <b-img
                src="/dashboard/admin/images/table/edit-w.svg"
                width="30"
                height="30"
              />
            </div>
            <div
              v-if="userProfileState.profileData.image"
              class="profile-icon profile-image-reset"
              @click="removeImage"
            >
              <b-img
                src="/dashboard/admin/images/table/refresh-w.svg"
                width="16"
                height="16"
              />
            </div>
          </div>
        </div>

        <div class="d-flex flex-column ml-1">
          <div class="m-50">
            <h2 class="font-weight-bolder text-center">
              {{ userProfileState.profileData.name }}
            </h2>
            <span class="show-text">{{ userProfileState.profileData.account }}</span>
          </div>
        </div>
      </div>

      <!-- 上傳圖片 -->
      <b-form-file
        ref="refInputEl"
        accept=".jpg, .png"
        :hidden="true"
        plain
        @change="loadImage($event)"
      />

      <b-modal
        id="user-image-modal"
        centered
        no-close-on-backdrop
        no-close-on-esc
        @hidden="cancel"
        @close="cancel"
      >
        <template #modal-title>
          <h4 class="m-0 model-header">
            圖片裁剪
          </h4>
        </template>

        <div class="image-cropper-area">
          <b-overlay
            variant="white"
            :show="isUploading"
            spinner-variant="primary"
            blur="0"
            opacity=".75"
            rounded="sm"
          >
            <div class="image-cropper-area-image">
              <cropper
                ref="cropper"
                :src="image"
                :stencil-props="{
                  aspectRatio: 1/1,
                }"
                :canvas="{
                  minHeight: 0,
                  minWidth: 0,
                  maxHeight: 300,
                  maxWidth: 300,
                }"
                :auto-zoom="false"
                :debounce="false"
                @change="onChange"
              />
            </div>
          </b-overlay>
        </div>

        <template #modal-footer>
          <b-button
            variant="outline-secondary"
            :disabled="isUploading"
            @click="cancel"
          >
            取消
          </b-button>

          <b-button
            variant="primary"
            :disabled="isUploading"
            @click="uploadImage"
          >
            <span v-if="!isUploading">
              確認
            </span>

            <b-spinner
              v-else
              small
            />
          </b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BFormFile, BAvatar, BImg, BSpinner, BButton, VBTooltip, BOverlay, BSkeletonWrapper,
} from 'bootstrap-vue'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import { useProfileFun } from '../useProfile'
import { useSwalToast, useAlert } from '@/libs/mixins/index'

export default {
  components: {
    BImg,
    BButton,
    BAvatar,
    BFormFile,
    BSpinner,
    BOverlay,
    BSkeletonWrapper,
    Cropper,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      close: true,
      image: null,
      result: {
        coordinates: null,
        image: null,
      },
    }
  },
  computed: {
    userProfileState() {
      return this.$store.state['user-profile']
    },
  },
  methods: {
    // -----------------------------------圖片上傳------------------------------------
    // 圖片預覽
    onChange({ coordinates, image }) {
      this.result = {
        coordinates,
        image,
      }
    },

    // 圖片裁剪
    crop() {
      const { canvas } = this.$refs.cropper.getResult()
      this.image = canvas.toDataURL()
    },

    // 載入圖片
    loadImage(event) {
      const dirNameSplit = event.target.files[0].name.split('.')
      const type = dirNameSplit[dirNameSplit.length - 1]
      if (!['jpg', 'png'].includes(type.toLowerCase())) return
      this.$bvModal.show('user-image-modal')
      const input = event.target
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = e => { this.image = e.target.result }
        reader.readAsDataURL(input.files[0])
      }
    },

    // (上傳)圖片
    uploadImage() {
      const { canvas } = this.$refs.cropper.getResult()
      this.isUploading = true
      if (canvas) {
        const formData = new FormData()
        canvas.toBlob(blob => {
          formData.append('data', blob)
          this.setUserImageUpload({
            data: formData,
          })
            .then(response => {
              const image = response.data.data

              const resolveUserInfo = {
                ...this.userProfileState.profileData,
              }
              resolveUserInfo.image = image
              this.$store.commit('user-profile/UPDATE_PROFILE_DATA_STATE', resolveUserInfo)
              this.useAlertToast(response.data.success, response.data.message)

              const userData = JSON.parse(localStorage.getItem('userData'))
              if (userData) {
                userData.image = image
                localStorage.setItem('userData', JSON.stringify(userData))
                this.$store.commit('app/UPDATE_USERDATA_STATE', userData)
              }

              this.isUploading = false
            })
            .catch(error => {
              this.useSwalAlertCenter(false, '上傳失敗!', error.response.data.message)
              this.isUploading = false
            })
        })
      }
      this.$refs.refInputEl.reset()
      this.image = null
      this.$bvModal.hide('user-image-modal')
      this.result.coordinates = null
    },

    // (刪除)圖片
    removeImage() {
      this.setUserImageDelete()
        .then(response => {
          const resolveUserInfo = {
            ...this.userProfileState.profileData,
          }
          resolveUserInfo.image = null
          this.$store.commit('user-profile/UPDATE_PROFILE_DATA_STATE', resolveUserInfo)
          this.useAlertToast(response.data.success, response.data.message)
        })
        .catch(error => {
          this.useSwalAlertCenter(false, '刪除失敗!', error.response.data.message)
        })
    },

    // 取消上傳
    cancel() {
      this.$bvModal.hide('user-image-modal')
      this.$refs.refInputEl.reset()
      this.result.coordinates = null
      this.image = null
    },
  },
  setup() {
    const isUploading = ref(false)

    const {
      setUserImageUpload,
      setUserImageDelete,
    } = useProfileFun()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    return {
      isUploading,
      useAlertToast,
      useHttpCodeAlert,
      setUserImageUpload,
      setUserImageDelete,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.avatar-preview {
  margin: 30px 0;
}

// 圖片上傳
.profile-image {
  position: relative;
  .profile-image-mask {
    display: none;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #00000080;
    user-select: none;
    .profile-icon {
      cursor: pointer;
    }
    .profile-image-reset {
      position: absolute;
      top: -8px;
      right: -8px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #69bdf2;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &:hover {
    .profile-image-mask {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
