// import { ref, watch } from '@vue/composition-api'
import {
  useCommenSettings, useAlert, // useTableComponent,
} from '@/libs/mixins/index'
import store from '@/store'
// import router from '@/router'

export const useProfileSetting = () => {
  const {
    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  } = useCommenSettings()

  const ui = {}

  return {
    ui,

    syncObject,
    refonlineTime,
    onlineTime,
    updateOnline,
  }
}

export const useProfileFun = () => {
  const {
    useAlertToast,
    useHttpCodeAlert,
  } = useAlert()

  const {
    syncObject,
  } = useCommenSettings()

  const blankProfileInfo = {
    id: null,
    ssid: null,
    image: null,
    account: null,
    name: null,
    last_login_at: null,
    last_login_ip: null,
    created_at: null,
    updated_at: null,
  }

  const setUserImageUpload = (...args) => store.dispatch('user-profile/setUserImageUpload', ...args)
  const setUserImageDelete = (...args) => store.dispatch('user-profile/setUserImageDelete', ...args)
  const setUserPasswordChange = (...args) => store.dispatch('user-profile/setUserPasswordChange', ...args)

  const getProfileInfoData = () => store.dispatch('user-profile/getProfileInfoData')
  const setProfileInfoUpdate = (...args) => store.dispatch('user-profile/setProfileInfoUpdate', ...args)

  const refreshProfileInfoData = () => {
    store.commit('user-profile/UPDATE_BUSY_LOADING_STATE', true)
    store.dispatch('user-profile/getProfileInfoData')
      .then(response => {
        const { data } = response.data
        const resolveData = {
          ...syncObject(blankProfileInfo, data),
        }

        store.commit('user-profile/UPDATE_PROFILE_DATA_STATE', resolveData)
        store.commit('user-profile/UPDATE_BUSY_LOADING_STATE', false)
      })
      .catch(error => {
        useHttpCodeAlert(error.response)
      })
  }

  return {
    getProfileInfoData,
    setProfileInfoUpdate,
    refreshProfileInfoData,
    setUserImageUpload,
    setUserImageDelete,
    setUserPasswordChange,
    useAlertToast,
    useHttpCodeAlert,
    syncObject,
  }
}
