<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        lg="4"
        order-xl="2"
      >
        <b-card>
          <AvatarPreview />
        </b-card>
      </b-col>

      <b-col
        cols="12"
        lg="8"
        order-xl="1"
      >
        <b-card>
          <div class="d-flex justify-content-between">
            <h4 class="font-weight-bolder">
              個人資料
            </h4>

            <div
              v-if="!userProfileState.isBusyLoading"
              class="d-flex"
            >
              <div
                v-b-tooltip.hover.focus.v-secondary
                title="重新整理"
                class="d-flex align-items-center actions-link-btn ml-25"
                @click="$emit('refresh-data')"
              >
                <b-img
                  src="/dashboard/admin/images/table/refresh.svg"
                  class="actions-link-btn-image"
                />
              </div>

              <div
                v-b-tooltip.hover.focus.v-secondary
                title="儲存"
                class="d-flex align-items-center actions-link-btn ml-25"
                @click="submitUpdateProfileInfo"
              >
                <b-img
                  src="/dashboard/admin/images/table/save.svg"
                  class="actions-link-btn-image"
                />
              </div>
            </div>
          </div>

          <div v-if="!userProfileState.isBusyLoading">
            <b-form-group
              label-cols="4"
              label="SSID"
              class="m-0 preview-col-from-group"
            >
              <div class="mt-50 preview-col-label">
                {{ userProfileState.profileData.ssid }}
              </div>
            </b-form-group>

            <b-form-group
              label="姓名"
              label-for="name"
              label-cols="4"
              label-cols-lg="5"
              label-cols-xl="4"
              class="edit-col-from-group"
            >
              <!-- <template #label>
                <label>
                  <b-img
                    :src="require('@/assets/images/pages/user/profile-user.svg')"
                    width="16"
                    class="mr-50"
                  />

                  <span class="edit-col-title">姓名</span>
                </label>
              </template> -->

              <b-input-group
                v-if="editCol === 'name'"
                class="input-group-merge"
              >
                <b-form-input
                  id="name"
                  v-model="profileData.name"
                  :placeholder="editColData ? editColData : '請輸入姓名'"
                />

                <b-input-group-append is-text>
                  <div>
                    <b-img
                      :src="require('@/assets/images/pages/ui/blue-line/checked-blue.svg')"
                      width="18"
                      class="mr-75 cursor-pointer"
                      @click="confirmEditCol"
                    />

                    <b-img
                      :src="require('@/assets/images/pages/ui/blue-line/close-blue.svg')"
                      width="18"
                      class="cursor-pointer"
                      @click="cancelEditCol"
                    />
                  </div>
                </b-input-group-append>
              </b-input-group>

              <div
                v-else
                class="edit-col-label"
                @click="selectEditCol('name')"
              >
                <span v-if="profileData.name"> {{ profileData.name }}</span>

                <span v-else>
                  <small class="text-muted">尚未設置</small>
                </span>

                <span class="selection-btn-icon">
                  <b-img
                    :src="require('@/assets/images/pages/ui/blue-line/pen.svg')"
                    height="15"
                    width="15"
                    class="cursor-pointer"
                  />
                </span>
              </div>
            </b-form-group>

            <b-form-group
              label-cols="4"
              label="帳號"
              class="m-0 preview-col-from-group"
            >
              <div class="mt-50 preview-col-label">
                {{ userProfileState.profileData.account }}
              </div>
            </b-form-group>

            <b-form-group
              label-cols="4"
              label="上次登入位置"
              class="m-0 preview-col-from-group"
            >
              <div class="mt-50 preview-col-label">
                {{ userProfileState.profileData.last_login_ip }}
              </div>
            </b-form-group>

            <b-form-group
              label-cols="4"
              label="上次登入時間"
              class="m-0 preview-col-from-group"
            >
              <div class="mt-50 preview-col-label">
                {{ userProfileState.profileData.last_login_at ? moment(userProfileState.profileData.last_login_at).format("YYYY-MM-DD HH:mm") : '---' }}
              </div>
            </b-form-group>

            <b-form-group
              label-cols="4"
              label="創建時間"
              class="m-0 preview-col-from-group"
            >
              <div class="mt-50 preview-col-label">
                {{ userProfileState.profileData.created_at ? moment(userProfileState.profileData.created_at).format("YYYY-MM-DD HH:mm") : '---' }}
              </div>
            </b-form-group>

            <b-form-group
              label-cols="4"
              label="上次更新"
              class="m-0 preview-col-from-group"
            >
              <div class="mt-50 preview-col-label">
                {{ userProfileState.profileData.updated_at ? moment(userProfileState.profileData.updated_at).format("YYYY-MM-DD HH:mm") : '---' }}
              </div>
            </b-form-group>
          </div>

          <b-skeleton-wrapper :loading="userProfileState.isBusyLoading">
            <template #loading>
              <div class="ml-50 mt-3">
                <b-skeleton
                  animation="wave"
                  width="90%"
                />

                <b-skeleton
                  animation="wave"
                  width="65%"
                />

                <b-skeleton
                  animation="wave"
                  width="80%"
                />

                <b-skeleton
                  animation="wave"
                  width="90%"
                />

                <b-skeleton
                  animation="wave"
                  width="70%"
                />

                <b-skeleton
                  animation="wave"
                  width="85%"
                />
              </div>
            </template>
          </b-skeleton-wrapper>
        </b-card>

        <profile-password />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BCard, BRow, BCol, BFormGroup, BImg, BInputGroupAppend, BInputGroup, BFormInput, VBTooltip,
  BSkeletonWrapper, BSkeleton, // BCardTitle,
} from 'bootstrap-vue'
import moment from 'moment'
import Ripple from 'vue-ripple-directive'
import AvatarPreview from './components/AvatarPreview.vue'
import ProfilePassword from './components/ProfilePassword.vue'
import { useProfileSetting, useProfileFun } from './useProfile'
import { useSwalToast, useAlert } from '@/libs/mixins/index'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BImg,
    // BCardTitle,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BSkeletonWrapper,
    BSkeleton,

    AvatarPreview,
    ProfilePassword,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  mixins: [useSwalToast],
  data() {
    return {
      editCol: null,
      editColData: null,
      missingFields: [],
    }
  },
  computed: {
    userProfileState() {
      return this.$store.state['user-profile']
    },
  },
  watch: {
    'userProfileState.isBusyLoading': {
      handler() {
        this.initCustomerInfo()
      },
      deep: true,
    },
  },
  mounted() {
    if (this.userProfileState.profileData) {
      this.initCustomerInfo()
    }
  },

  methods: {
    moment,
    // (初始化)會員資料
    initCustomerInfo() {
      const resolveData = {
        ...this.syncObject(this.blankProfileInfo, this.userProfileState.profileData),
      }
      this.profileData = resolveData
    },

    // (選取)編輯欄位
    selectEditCol(type) {
      if (this.editCol) {
        this.useAlertToast(false, '尚有編輯中的欄位未確認')
        return
      }
      this.editCol = type
      this.editColData = this.profileData[type]
    },

    // (取消)編輯欄位
    cancelEditCol() {
      this.profileData[this.editCol] = this.editColData
      this.editCol = null
    },

    // (確認)編輯欄位
    confirmEditCol() {
      // this.onSubmitUpdate()
      this.editCol = null
    },

    // (觸發)儲存
    submitUpdateProfileInfo() {
      if (this.editCol) {
        this.useAlertToast(false, '尚有編輯中的欄位未確認')
        return
      }
      if (this.isBusy) return
      this.isBusy = true

      const requiredFields = {
        name: '姓名',
      }

      this.missingFields = []

      for (let i = 0; i < Object.keys(requiredFields).length; i += 1) {
        if (this.profileData[Object.keys(requiredFields)[i]] === null || this.profileData[Object.keys(requiredFields)[i]] === '') {
          this.missingFields.push({
            lable: Object.values(requiredFields)[i],
            value: Object.keys(requiredFields)[i],
          })
        }
      }

      if (this.missingFields.length > 0) {
        this.useAlertToast(false, `${this.missingFields[0].lable}不得為空`)
        return
      }

      this.setProfileInfoUpdate({
        ...this.profileData,
      })
        .then(response => {
          const resolveUserInfo = {
            ...this.userProfileState.profileData,
            ...this.profileData,
          }
          this.$store.commit('user-profile/UPDATE_PROFILE_DATA_STATE', resolveUserInfo)
          this.useAlertToast(response.data.success, '個人資料更新成功')

          const userData = JSON.parse(localStorage.getItem('userData'))
          if (userData) {
            userData.name = this.profileData.name
            localStorage.setItem('userData', JSON.stringify(userData))
            this.$store.commit('app/UPDATE_USERDATA_STATE', userData)
          }
          this.isBusy = false
        })
        .catch(error => {
          this.useHttpCodeAlert(error.response)
          this.isBusy = false
        })
    },
  },
  setup() {
    const {
      ui,
      syncObject,
    } = useProfileSetting()

    const {
      useAlertToast,
      useHttpCodeAlert,
    } = useAlert()

    const {
      setProfileInfoUpdate,
    } = useProfileFun()

    const profileData = ref(null)
    const isBusy = ref(false)

    const blankProfileInfo = {
      name: null,
    }

    const resetProfileData = () => {
      profileData.value = JSON.parse(JSON.stringify(blankProfileInfo))
      isBusy.value = false
    }

    resetProfileData()

    return {
      ui,
      isBusy,
      profileData,
      syncObject,
      useAlertToast,
      useHttpCodeAlert,
      blankProfileInfo,
      setProfileInfoUpdate,
      resetProfileData,
    }
  },
}

</script>

<style lang="scss" scoped>
.edit-col-from-group {
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;

  .edit-col-title {
    font-size: 14px;
    font-weight: bold;
    margin-top: 3px;
  }

  .edit-col-label {
    padding: 0 10px;
    cursor: pointer;
    border-radius: 5px;
    word-break: break-all;
    overflow: hidden;
    .selection-btn-icon {
      display: none;
    }
    &:hover {
      background: #a9a8a820;
      // box-shadow: 0 4px 4px 0 rgba(#6f6f6f, 0.25);
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 36px;
      .selection-btn-icon {
        display: inline-block;
      }
    }
  }

  .edit-col-select {
    // background-color: red;
    width: calc(100% - 80px);
    height: 36px;
  }
}

.preview-col-from-group {
  .preview-col-label {
    padding: 0 10px;
  }
}
</style>
